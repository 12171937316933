import React, { useEffect, useState, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import CoursesListBox from "../../common/CoursesListBox";
import { courseArrayAdd, courseArrayClear } from "../../state/actionCreater";

import {createCourse} from "../../services/course.service"

export default function CreateCourse() {
  const dispatch = useDispatch();
  const [courseDetails, setCourseDetails] = useState({
    courseName: "",
    courseDuration:"",
    courseDescription: "",
    courseType: "",
    courseEligibility: ""
  });
  let oneval = 0;
  const [loading, isLoading] = useState(false);
  console.log("re-render:", oneval);
  let cr = useSelector((state) => state.courseArrayReducer);
  function addArray() {
    dispatch(
      courseArrayAdd({
        courseName: courseDetails.prevCourseName,
        coursePercentage: courseDetails.prevCoursePercentage,
        courseDescription: courseDetails.prevCourseDescription,
      })
    );
    isLoading(!loading);
    console.log("cr: -->", cr);
  }
  function changeHandler(e) {
    e.preventDefault();
    oneval += 10
    console.log("oneval--> ",oneval)
    setCourseDetails({
      ...courseDetails,
      [e.target.name]: e.target.value,
    });
  }
  // onSubmit to BACKEND API

  function createTheCourse(e){
    e.preventDefault()
    createCourse(courseDetails)
    
  }

  useEffect(
    (e) => {
      console.log("useEffect");
    },
    [loading]
  );
  // useEffect(()=>{
  //   dispatch(courseArrayClear({}))
  // }, [])

  return (
    <div className="bg-slate-200 p-2 grid md:grid-cols-2 grid-cols-1">
      <div className="py-2 block md:flex md:flex-wrap md:justify-center content-start">
        <input
          name="courseName"
          onChange={changeHandler}
          placeholder="Course Name"
          className="lg:w-[470px] md:w-[400px] w-full h-[35px] rounded-md px-2 m-2"
        />

        <textarea
          name="courseDescription"
          onChange={changeHandler}
          type="text"
          placeholder="Course Descriptions"
          className="lg:w-[470px] md:w-[400px] w-full h-[110px] rounded-md px-2 pt-3 m-2"
        />

        {/* <div className=''>
          <input placeholder='Course Name' className='md:w-[470px] w-full h-[35px] rounded-md px-2' />
          </div>
          <div className=''>
          <input placeholder='Course Name' className='md:w-[470px] w-full h-[35px] rounded-md px-2' />
          </div> */}
      </div>
      {/* first column above and second column below */}
      <div className="p-2 md:flex flex-wrap content-start">
        <h5 className="w-full text-lg text-sky-600">Prequest Courses</h5>

        <input
          name="prevCourseName"
          onChange={changeHandler}
          placeholder="Course Name"
          className="lg:w-[470px] md:w-[400px] w-full h-[35px] rounded-md px-2 my-2"
        />

        <input
          name="prevCoursePercentage"
          placeholder="Percentage"
          onChange={changeHandler}
          className="lg:w-[470px] md:w-[400px] w-full h-[35px] rounded-md px-2 my-2"
        />
        <textarea
          name="prevCourseDescription"
          placeholder="Description"
          onChange={changeHandler}
          className="lg:w-[470px] md:w-[400px] w-full h-[110px] rounded-md px-2 pt-3 my-2"
        />
        <div className="flex justify-center w-full lg:w-[470px] md:w-[400px] py-3">
          <button
            onClick={addArray}
            className="bg-blue-500 py-2 px-4 rounded-lg text-white hover:bg-blue-400"
          >
            Added To Track
          </button>
        </div>

        <h5 className="w-full mt-5">Prequest track with serials</h5>

        {/* {showArrayDom } */}
        <CoursesListBox height="180px" width="120px" />
      </div>
      {/* button */}
      <div className="col-span-2 flex justify-center mt-6">
        <button
          className="py-2 px-3 bg-green-500 hover:bg-green-600 rounded-md text-white"
          onClick={createTheCourse}
        >
          Create Course
        </button>
      </div>
    </div>
  );
}
