import React from 'react'
import styles from "./AboutUs.module.css"
// import Header from '../../common/Header'

export default function AboutUs() {
  return (
    <>
        {/* <Header /> */}
        <p className={styles.p}>Hello about</p>
    
    </>
  )
}
