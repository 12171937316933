// for new es2015
import React, {Component} from "react";
import styles from "./MainPage.module.css"

// its common syntax
// let React,{Component} = require('react') 



class MainPage extends Component {
    render(){
        return(
        <div>
            <p className={styles.p}>it is the Main page</p>
            
            </div>
        )
    }
}


export default MainPage


