import React from 'react'
import { useNavigate } from 'react-router-dom';
import logo from '../assets/image/careerEnv-logo.svg';

import { useSelector } from 'react-redux';
import UserAvatar from './userAvatar';





export default function Header() {
    const navigate = useNavigate()
    let isLoginState = useSelector((state) => state.loginReducer)
        return (
            <div className='h-[60px] w-screen bg-black px-6 md:px-28'>
                <div className='flex justify-between'>
                    <div className='w-28 sm:w-48 mt-5 sm:mt-3'>
                        <img className='w-full' src={logo} alt="careerPath Logo"></img>
                    </div>
                    <div className='flex'>
                        <div className='mt-4 text-white text-l sm:flex hidden space-x-5 '>
                            <a className={`${window.location.pathname === '/' || window.location.pathname === '/user/dashboard' ? 'text-gray-400':''}`} href='/'>Home</a>
                            <a className={`${window.location.pathname === '/about' ? 'text-gray-400':''}`} href='/about'>About Us</a>
                            <a className='' href='/contact'>Contact Us</a>
                            {/* <a></a>
                            <a></a> */}
                        </div>
                        {
                            isLoginState ?
                            <UserAvatar />
                            :
                            (<button className='mt-4 ml-6 sm:mt-4 w-28 sm:w-36 h-7 sm:h-7 text-sm sm:text-l bg-green-600  text-white align-center rounded-lg' onClick={()=>navigate("/login")}>login/ Signup</button>) 
                        }
                    </div>
                    
                </div>
            </div>
    )
}
