
import './App.css';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import IndexRoutes from "./routes/index"
// import MainPage from './components/MainPage/MainPage';
// import AboutUs from './components/AboutUs/AboutUs';
// import LoginPage from "./components/LoginPage/LoginPage";
// import Header from './common/Header';

// //protected Route
// import Dashboard from './components/Dashboard/Dashboard';
import store from "./state/store"
import { Provider } from 'react-redux';

function App() {
  return (
    <div className='w-screen'>
      <Provider store={store}>
          <IndexRoutes />
          {/* <Header />
          <Routes>
            
            <Route exact path='/' element={<MainPage />} />
            <Route exact path='/about' element={<AboutUs />} />
            <Route exact path="/login" element={<LoginPage />} />

            <Route exact path="/user/dashboard" element={<Dashboard />} />
            <Route exact path="*" element={<Navigate to='/' />} />
          </Routes> */}
          <ToastContainer 
            position="top-center"
            autoClose={2000}
          />

      </Provider>
    </div>
  );
}

export default App;
