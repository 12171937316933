import axios from "../middleware/axios"
import Cookie from 'js-cookie'
import { CONSTANT } from "../config/constant"


const login = async(email, password) =>{
    console.log("hit on the login service")
    try{
        let callbackData = await axios.post(CONSTANT.API.LOGIN, {email, password})
        console.log(callbackData)
        console.log('after call abck')
        if(callbackData){
            Cookie.set('token', callbackData.data.token)
            Cookie.set('id', callbackData.data.id)
            Cookie.set('email', callbackData.data.email)
            Cookie.set('role', callbackData.data.role)
        }
        return {status : 'successfully login'}
    }
    catch(err){
        console.log(err)
        if(err.response.data.status === 'user not found'){
            return {status : "user not found"}
        }
        if(err.response.data.status === 'user Password is wrong'){
            return {status : "user Password is wrong"}
        }
        

    }
}

// const logout = () => {
    
// }



export {login}