import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate} from 'react-router-dom'

export default function UserAvatar() {
    const navigate = useNavigate()
    let [hoverMenu, setHoverMenu] = useState(false)
    let [isAdmin, setAdmin] = useState(false)
    const onMouseHover = (data) =>{
        setHoverMenu(data)
    }
    useEffect(()=>{
        if(Cookies.get('role') === 'admin'){
            setAdmin(true)
        }else{
            setAdmin(false)
        }
    }, []);
    function onLogoutClick(){
        Cookies.remove('token')
        Cookies.remove('id')
        Cookies.remove('role')
        navigate(0)
    }
    
  return (
    <div className='mt-3 ml-5 w-10 h-10 rounded-full border-[3px] border-green-600 bg-white relative' onClick={(e) => onMouseHover(!hoverMenu)} onMouseLeave={(e) => onMouseHover(false)}>
        {
            hoverMenu ? (
                <div className='w-28 md:w-32 h-fit backdrop-blur-md bg-white/30 z-10 absolute top-9 -left-14 sm:-left-12 rounded-md flex flex-col'>
                    <Link className='w-full text-center pt-1 pb-1 border-b-[1px] border-slate-500' to="/user/profile" >Your Profile</Link>
                    <Link className='w-full text-center pt-1 pb-1 border-b-[1px] border-slate-500' to="/user/dashboard" >Dashboard</Link>
                    {isAdmin? <Link className='w-full text-center pt-1 pb-1 border-b-[1px] border-slate-500' to="/user/adminconsole" >Admin Console</Link>: ''}
                    <p className='text-center pt-1 mt-0 pb-1 text-red-600' onClick={()=> onLogoutClick()}>Logout</p>
                </div>
            ) : ''
        }
    </div>
  )
}
