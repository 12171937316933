import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import Cookies from 'js-cookie';

import Header from '../common/Header';

import MainPage from '../components/MainPage/MainPage';
import AboutUs from '../components/AboutUs/AboutUs';
import LoginPage from '../components/LoginPage/LoginPage';

import ProtectedRoute from '../middleware/ProtectedRoute';

import Dashboard from '../components/Dashboard/Dashboard';

// redux part
import { useDispatch, useSelector } from 'react-redux';
import { updateLogin } from '../state/actionCreater';
import UserProfile from '../components/UserProfile/UserProfile';
import SignUp from '../components/SignUp/SignUp';


// admin routes
import Adminroutes from './adminRoutes';


export default function AllRoutes() {
   let isLoginState = useSelector((state) => state.loginReducer)
  const dispatch = useDispatch()
  if(Cookies.get('token') && Cookies.get('id') && !isLoginState){
      dispatch(updateLogin(true))
  }
  return (
    <>
    <Header />
      <Routes>
      
        <Route exact path='/' element={<MainPage />} />
        <Route exact path='/about' element={<AboutUs />} />
        <Route exact path="/login" element={<LoginPage />} />
        <Route exact path="/signup" element={<SignUp />} />
                
        {/* this have all the admin routes        */}
        {Adminroutes} 

        <Route exact path="/user/dashboard" element={
          <ProtectedRoute>
              <Dashboard />
          </ProtectedRoute>
          
        } />

        <Route exact path="/user/profile" element={
          <ProtectedRoute>
              <UserProfile />
          </ProtectedRoute>
          
        } />

        
        


        {/* all routes should be above this */}
        <Route path="/*" element={<Navigate to='/' />} />
      </Routes>
      </>
  )
}

