import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CourseItemInBox from './CourseItemInBox';
export default function CoursesListBox(props) {
    // let courses = useDispatch

    let courses = useSelector(state => state.courseArrayReducer);
    let courseArray = [];
    courses.forEach(item=> courseArray.push(<CourseItemInBox key={courses.indexOf(item)} itemNum={courses.indexOf(item)} courseName={item.courseName} coursePercentage={item.coursePercentage}>{item.courseName}</CourseItemInBox>))
    console.log(props)
    return (
        <div className="min-h-[200px] w-full lg:w-[470px] md:w-[400px] bg-slate-200 border-[1px] rounded-sm p-1 border-slate-500">
            {courseArray}
        </div>
    )
}
