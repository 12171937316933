import { Route } from "react-router-dom";
import AdminConsole from "../components/AdminConsole/AdminConsole";
import CreateCourse from "../components/AdminConsole/CreateCourse";

import {AdminRouteProtect} from "../middleware/ProtectedRoute";

let Adminroutes=[ 
<Route key="adminmain" exact path='/user/adminconsole' element={
    <AdminRouteProtect>
    <AdminConsole />
    </AdminRouteProtect>
     
} />,
<Route key="admincreate" exact path='/user/adminconsole/createcourse' element={
    <AdminRouteProtect>
    <CreateCourse />
    </AdminRouteProtect>
     
} />,
<Route key="adminmainview" exact path='/user/adminconsole/viewcourse' element={
    <AdminRouteProtect>
    <AdminConsole />
    </AdminRouteProtect>
     
} />
]


export default Adminroutes;