function courseArrayReducer(state=[], action){
    if(action.type==='courseArrayPush'){
        console.log("testing reducer", action.payload)
        state.push(action.payload.coursesArray)
        console.log(state)
        return state
    }
    if(action.type==='courseArray'){
        console.log("testing reducer", action.payload)
        state.push(action.payload.coursesArray)
        console.log(state)
        return state
    }
    if(action.type === 'courseArrayClear'){
        state = [];
    }
    return state
}


export default courseArrayReducer;