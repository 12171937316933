import React from 'react'

export default function CourseItemInBox(props) {
  return (
    <div className='rounded-md bg-white h-12 px-2 py-1 w-full mb-1'>
        <div className='flex'>
            <div className='w-5 align-middle h-full'><p className='align-middle mt-1'>{props.itemNum + 1}</p></div>
            <div className=''>
                <p className='text-[12px] text-black'>Course Name : {props.courseName}</p>
                <p className='text-[12px] text-black'>Percentage: {props.coursePercentage}</p>
            </div>
        </div>  
    </div>
  )
}
