import React, {  useEffect, useState } from 'react'
import {login} from "../../services/auth.service"
import { toast} from 'react-toastify'
import { useNavigate } from 'react-router-dom'

import SignupAddonComp from './SignupAddonComp'

// redux part
import { useDispatch, useSelector } from 'react-redux'
import {updateLogin} from "../../state/actionCreater/index"




export default function LoginPage(){
    
  // redux part
  let isLoginState = useSelector((state) => state.loginReducer )
  let dispatch = useDispatch()

  // end redux part
    let [password, setPassword] = useState('')
    let [email, setEmail] = useState('')
    let [errorMessage, seterrorMessage] = useState('')

    let [loginView, setLoginView] = useState(true);

    const navigate = useNavigate()

    useEffect(() => {
      if(isLoginState){
        navigate("/")
      }
    })
    

    const changeHandler = (key,value) => {
        if(key === 'email'){setEmail(value)}
        if(key === 'password'){setPassword(value)}
      }


    const onSubmit = async(e) =>{
        e.preventDefault()
        if(email !== ''&& password !== '' && password.length > 6 ){
          seterrorMessage('')
          let loginResponse = await login(email,password)
          console.log(loginResponse)
          if(loginResponse.status === 'successfully login'){
            toast.success('Login Successsful')
            dispatch(updateLogin(true))
            navigate("/user/dashboard")
          }
          if(loginResponse.status === 'user not found'){
            toast.error('user not found')
          }
          if(loginResponse.status === 'user Password is wrong'){
            toast.error('password not match')
          }
        }
        else{
          if(email === ''){
            seterrorMessage('*pls enter email')
          }
          else if(password === '' || password.length < 6 ){
            seterrorMessage('*use password minimum 6 charecter ')
          }
        }
        
      }

  return (
    <div className='bg-gradient-to-r from-purple-900 to-blue-900 flex justify-center w-screen h-[calc(100vh-60px)]'>

      <form className={loginView? 'w-[300px]  lg:w-[400px] h-[390px] bg-slate-100 mt-10 rounded-[8px]' : 'w-[300px]  lg:w-[400px] h-[640px] bg-slate-100 mt-10 rounded-[8px]'}>
      {loginView? <>
        <div className='form pt-8 pb-2 px-4 md:px-4 lg:px-14'>
            <label className="block text-base pt-3 pb-2 ml-2 text-slate-500">
              UserName/Email
            </label>
            <input name='email' type='text' className='w-full h-11 rounded-[8px] px-4 focus:outline-none focus:border-blue-400 border-2' placeholder='Type Here' onChange={e => changeHandler('email',e.target.value)} />
            
            <label className="block text-base pt-3 pb-2 ml-2 mt-4 text-slate-500">
              Password
            </label>
            <input name='password' autoComplete='' type="password" className='w-full h-11 rounded-[8px] px-4 focus:outline-none focus:border-blue-400 border-2' placeholder='Type Here' onChange={e => changeHandler('password',e.target.value)} />
          </div>

        <div className='flex w-full justify-start px-4 md:px-14 h-8 pb-4'>
          <p className="sm:text-sm text-[13px] ml-2 text-red-700">{errorMessage}</p>
        </div>

        <div className='w-full flex justify-center'>
          <button className='bg-sky-500 w-28 h-10 text-lg text-white rounded-[8px] hover:bg-sky-600' onClick={onSubmit}>Login</button>
        </div></> : <SignupAddonComp />}
        <p className='mt-5 px-5 text-black'>Create New Account?<span className='ml-2 text-sky-600 cursor-pointer font-semibold' onClick={()=> setLoginView(!loginView)}>Click Here</span></p>

      </form>
      
      
    </div>
    )
  }





