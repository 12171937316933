import React from 'react'
import { Link } from 'react-router-dom'


export default function AdminConsole() {
  return (
    <>
    <div>AdminConsole</div>
    <Link className='bg-slate-700 py-2 px-4 text-white' to="/user/adminconsole/Createcourse" >Create Course</Link>      
    </>
  )
}
