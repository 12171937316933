export const updateLogin = (isLogin) =>{
    return(
        (dispatch) =>{
            dispatch({
                type : 'updateLogin',
                payload : {
                    isLogin : isLogin
                }
            })
        }
    )
}

export const courseArrayAdd = (coursesArray) => {
    return(
        (dispatch)=>{
            dispatch({
                type: 'courseArrayPush',
                payload:{
                    coursesArray: coursesArray
                }
            })
    })
}

export const courseArrayUpdate = (coursesArray) => {
    return(
        (dispatch)=>{
            dispatch({
                type: 'courseArray',
                payload:{
                    coursesArray: coursesArray
                }
            })
    })
}
export const courseArrayClear = (coursesArray) => {
    return(
        (dispatch)=>{
            dispatch({
                type: 'courseArrayClear',
                payload:{}
            })
    })
}
