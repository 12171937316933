import React, { useEffect, useState } from 'react'
import {signUp} from "../../services/user.service"


export default function SignUp() {
    
    let [name, setName] = useState('')
    let [email, setEmail] = useState('')
    let [password, setPassword] = useState('')
    let [repassword, setRepassword] = useState('')
    let [match, setMatch] = useState(false)
    let [isBlank, setIsBlank] = useState(false)
    const createUser = async () => {
        if(name && email && /\S+@\S+\.\S+/.test(email) && match){
            console.log("match all data")
            let newUserData = {name: name, email: email, password:password}
            
            await signUp(newUserData).then((response)=>{
                console.log(response)
            }).catch(error => {
                console.log(error)
            })
            
        }
        else{
            console.log('does not match all data')
            setIsBlank(true)
        }
    }

    const changeHandler = (e) => {
        setRepassword(e.target.value)
    }

    useEffect(()=>{
        if(password === repassword){
            setMatch(true)
        }
        else{
            setMatch(false)
        }
    }, [password, repassword])


    return (
        <div className='bg-gradient-to-r flex justify-center from-purple-600 to-blue-600 h-[calc(100vh-60px)]'>
            <form className='w-[300px] lg:w-[400px] h-fit mt-20 rounded-md bg-black px-5 py-5'>
                <p className={`text-red-700 ${isBlank? '': 'invisible'}`} >* Fill all the mandatory field</p>
                <label className="block text-base pt-3 pb-2 ml-2 text-slate-500">
                    Full Name *
                </label>
                <input name='name' placeholder='Name' type='text' className='w-full h-11 rounded-[8px] px-4 focus:outline-none focus:border-blue-400 border-2' onChange={(e)=> setName(e.target.value)} />
                
                <label className="block text-base pt-3 pb-2 ml-2 text-slate-500">
                    Email Address *
                </label>
                <input name='email' placeholder='Email Address' type="email" className='w-full h-11 rounded-[8px] px-4 focus:outline-none focus:border-blue-400 border-2' onChange={(e)=> setEmail(e.target.value)} />
                
                <label className="block text-base pt-3 pb-2 ml-2 text-slate-500">
                    Password *
                </label>
                <input name='password' placeholder='Password *' type="password" className='w-full h-11 rounded-[8px] px-4 focus:outline-none focus:border-blue-400 border-2' onChange={(e)=> setPassword(e.target.value)} />

                <label className="block text-base pt-3 pb-2 ml-2 text-slate-500">
                    Re-enter Password
                </label>
                <input name='repassword' placeholder='re-enter Passowrd *' type="password" className={`w-full h-11 rounded-[8px] px-4 focus:outline-none border-2 ${match ? 'border-green-400' : 'border-red-700'  }`} onChange={(e)=>changeHandler(e)} />

                <div className='w-full flex justify-center mt-7 '>
                    <button className='w-24 h-8 bg-blue-700 rounded-md text-white' onClick={e =>{e.preventDefault(); createUser()}}>SignUp</button>
                </div>
                
            </form>
        </div>
  )
}
