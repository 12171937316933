import axios from 'axios'
import Cookie from "js-cookie"


console.log("from axios js")
console.log(process.env.REACT_APP_API_HTTP)

let base = axios.create({
    baseURL: `${process.env.REACT_APP_API_HTTP}${process.env.REACT_APP_API_LINK}`
})

base.interceptors.request.use(
    function(config){
        const token = Cookie.get('token')
        const id = Cookie.get('id')
        if(id && token){
            config.headers = {Authorization: `Bearer ${token}`, id: id}
        }
        return config
    },
    function(err){
        return Promise.reject(err)
    }
)

export default base;