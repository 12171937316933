import React, { useState } from 'react'
import { signUp } from '../../services/user.service';

export default function SignupAddonComp() {
  
  const [email,setEmail] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [repassword, setRePassword] = useState('');
  const [errorMessage, seterrorMessage] = useState('');

  function changeHandler(key, value){
    if(key==='name'){
      setName(value)
    }
    if(key==='email'){
      setEmail(value)
    }
    if(key==='password'){
      setPassword(value)
    }
    if(key==='repassword'){
      setRePassword(value)
    }
  }


  const onSubmit = async () =>{
    
    console.log(email, password, name, repassword);
    seterrorMessage('error')
    if(password > 5 && name && repassword !== '' && email && /\S+@\S+\.\S+/.test(email)){
      if(password === repassword){
        let newUserData = {name: name, email: email, password:password}
            
            await signUp(newUserData).then((response)=>{
                console.log(response)
            }).catch(error => {
                console.log(error)
            })
      }
      else{
        
        seterrorMessage('password not match')
        
      }
    }else{
    if(! /\S+@\S+\.\S+/.test(email)){
      seterrorMessage('email not valid')
    }
  }
  }
  
  return (
    <>
      <div className='form pt-8 pb-2 px-4 md:px-4 lg:px-14'>
      <label className="block text-base pt-3 pb-2 ml-2 text-slate-500">
              Name
            </label>
            <input name='Name' type='text' className='w-full h-11 rounded-[8px] px-4 focus:outline-none focus:border-blue-400 border-2' placeholder='Type Here' onChange={e => changeHandler('name',e.target.value)} />

            <label className="block text-base pt-3 pb-2 ml-2 text-slate-500">
              Email
            </label>
            <input name='email' type='text' className='w-full h-11 rounded-[8px] px-4 focus:outline-none focus:border-blue-400 border-2' placeholder='Type Here' onChange={e => changeHandler('email',e.target.value)} />
            
            <label className="block text-base pt-3 pb-2 ml-2 mt-4 text-slate-500">
              Password
            </label>
            <input name='password' autoComplete='' type="password" className='w-full h-11 rounded-[8px] px-4 focus:outline-none focus:border-blue-400 border-2' placeholder='Type Here' onChange={e => changeHandler('password',e.target.value)} />

            <label className="block text-base pt-3 pb-2 ml-2 mt-4 text-slate-500">
              Re-Enter Password
            </label>
            <input name='password' autoComplete='' type="password" className='w-full h-11 rounded-[8px] px-4 focus:outline-none focus:border-blue-400 border-2' placeholder='Type Here' onChange={e => changeHandler('repassword',e.target.value)} />
          </div>

        <div className='flex w-full justify-start px-4 md:px-14 h-8 pb-4'>
          <p className="sm:text-sm text-[13px] ml-2 text-red-700">{errorMessage}</p>
        </div>

        <div className='w-full flex justify-center'>
          <button className='bg-sky-500 w-28 h-10 text-lg text-white rounded-[8px] hover:bg-sky-600' onClick={(e) => {e.preventDefault();onSubmit()}}>Login</button>
        </div>
    </>
  )
}
