import React, { useEffect, useState } from 'react'
import { userProfile } from '../../services/user.service'

export default function UserProfile() {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const userDetails =async () =>{
        // const response = await userProfile()
        try{
            const response = await userProfile()
            console.log(response)
            setName(response.data.name)
            setEmail(response.data.email)
        }catch(err){
            console.log(err)
        }
    }
    const changeHandler= (data) =>{
        switch(data.target.name){
            case 'email':
                setEmail(data.target.value);
                break;
            case 'name':
                setName(data.target.value);
                break;
            default:
                break;
        }
    }
    useEffect(()=>{
        console.log("run useeffect");
        userDetails()
    },[])
  return (
        <div className='w-full flex justify-center'>

            <div className='w-full mt-5 md:mt-16 px-4 md:px1 md:w-4/12 max-w-[430px] drop-shadow-md bg-white/30'>
                
                <div className='flex-col flex pb-5'>
                    <label htmlFor="name" className='pl-1 text-lg font-medium'>Name</label>
                    <input id="name" name="name" onChange={(e)=>changeHandler(e)} value={name} className='px-2 py-1.5 text-sm bg-gray-100 border border-gray-300 text-gray-800 rounded-lg focus:bg-white focus:border-blue-800 outline-none' />
                </div>

                <div className='flex-col flex'>
                    <label htmlFor="email" className='pl-1 text-lg font-medium'>Email<span className='text-red-600'>*</span></label>
                    <input id="email" name="email" value={email} onChange={(e)=>changeHandler(e)} className='px-2 py-1.5 text-sm bg-gray-100 border border-gray-300 text-gray-800 rounded-lg focus:bg-white focus:border-blue-800 outline-none' />
                </div>
                


            </div>
            
        </div>
    )
}
