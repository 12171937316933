import { combineReducers } from "redux";
import loginReducer from "./loginReducer"
import courseArrayReducer from './courseArrayReducer'

const reducers = combineReducers(
    {
        loginReducer : loginReducer,
        courseArrayReducer : courseArrayReducer
    }
)


export default reducers;