// import axios from "axios"
import { CONSTANT } from "../config/constant"
import axios from "../middleware/axios"



export const signUp = async(newUserData) => {
    return await axios.post(CONSTANT.API.SIGNUP, newUserData)
}

export const userProfile = async () =>{
    return await axios.get(CONSTANT.API.USERPROFILE)
}

