import React from 'react'
import { Navigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import { useDispatch } from 'react-redux'
import { courseArrayClear } from '../state/actionCreater'

export default function ProtectedRoute({children}) {
    console.log('pro')
    if(!Cookies.get('token') && !Cookies.get('id')){
       return <Navigate to="/" replace />
    }
    return children
}

export function AdminRouteProtect({children}) {
    console.log('run protection')
    if(Cookies.get('role') === 'admin'){
        return children
    }
    console.log('pro admin')
    return <Navigate to="/" replace/>
}   