import axios from '../middleware/axios'
import { CONSTANT } from "../config/constant"
// import axios from "../middleware/axios"

const createCourse = async (courseDetailsObj, previosCoursesArray) =>{
    console.log( 'courseDetailsObj: ' ,courseDetailsObj);
    console.log('previosCoursesArray: ', previosCoursesArray)
    // try{
    //     let callbackData = await axios.post(CONSTANT.API.CREATECOURSE, {})
    //     console.log('callback Data: ', callbackData)
    // }catch(e){
    //     console.log('e error: ', e)
    // }

}


export {createCourse}